@import "../../base/colors";

[data-theme="mint-delight"] {
  --color-primary: #00726d;
  --color-primary-rgb: 0, 114, 109;
  --color-primary-shade: #004441;
  --color-primary-shade-rgb: 0, 68, 65;
  --color-primary-tint: #338e8a;
  --color-primary-tint-rgb: 51, 142, 138;
  --color-primary-light: #e6f5ff;
  --color-primary-light-rgb: 230, 245, 255;

  --color-secondary: #7ee6e6;
  --color-secondary-rgb: 126, 230, 230;
  --color-secondary-shade: #65b8b8;
  --color-secondary-shade-rgb: 101, 184, 184;
  --color-secondary-tint: #98ebeb;
  --color-secondary-tint-rgb: 152, 235, 235;

  --color-tertiary: #a5df9f;
  --color-tertiary-rgb: 165, 223, 159;
  --color-tertiary-shade: #84b27f;
  --color-tertiary-shade-rgb: 132, 178, 127;
  --color-tertiary-tint: #b7e5b2;
  --color-tertiary-tint-rgb: 183, 229, 178;

  @extend .neutral-colors;
}
