@use "src/styles/base/mixins/rem";

.l-modal {
  position: relative;

  height: 100%;

  &__header {
    position: sticky;
    top: 0;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding: 16px 20px;

    background-color: var(--color-light);

    [mat-dialog-close] {
      font-size: rem.rem(28px);

      color: var(--color-dark);

      margin-left: 24px;

      border-radius: 8px;

      i {
        display: block;
      }
    }

    [mat-dialog-title] {
      margin: 0;
      padding: 0;

      &::before {
        display: none;
      }
    }
  }
}
