[mat-dialog-content],
[mat-dialog-title] {
  font-family: unset !important;
  line-height: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
  letter-spacing: unset !important;
}

.mat-mdc-dialog-content {
  max-height: 100vh !important;

  height: 100%;

  padding: 0 !important;

  display: flex !important;
  flex-direction: column !important;
}
