/* Estilos de base para projetos da Vale Saúde.(recomenda-se o use do normalize junto à estes estilos -> https://necolas.github.io/normalize.css/) */

/*As regras sugeridas por esses estilos, seguem padrões seguidos pelo time de front da Vale Saúde.*/

/*Estilos do documento*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: unset;
  }
}

body {
  min-height: 100%;
  margin: 0;

  display: flex;
  flex-direction: column;
}

/*Ajuste de font-size para o uso do rem + fix no MS Edge*/
html,
body {
  font-size: 16px;
}

/*Estilos de texto*/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/*Estilos de listas*/
ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

/*Estilos para links*/
a {
  color: inherit;
  text-decoration: none;
}

/*Estilos para deixar links para páginas, em novas abas, mais seguros*/
a[target="_blank"]:not([rel="noreferrer noopener"]) {
  content: "Sua tag <a> deve possuir o atributo rel com os valores noreferrer noopener";
  outline: solid red 3px;
}

/*Estilos para imagens*/
img {
  display: block;
  max-width: 100%;
}

/* Verifica se as imagens possuem os atributos mínimos definidos pelo time de front-end  */
img:not([alt]),
img:not([width]),
img[width=""],
img:not([height]),
img[height=""],
img:not([loading]) {
  content: "Sua tag <img> não possui algum dos seguintes atributos: alt, width, height ou loading";
  outline: solid red 6px;
  outline-offset: -6px;
}

/* Reset para os estilos dos botões */
button {
  background: none;
  border: none;
  padding: 0;
}

button:not(:disabled) {
  cursor: pointer;
}

button:not([type]),
button[type=""] {
  content: "Sua tag <button> deve possuir o atributo type";
  outline: solid red 3px;
}

i:not([aria-hidden]) {
  content: "Sua tag <i> deve possuir arria-hidden(true ou false)";
  outline: solid red 3px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

[hidden] {
  visibility: hidden;
}

hr {
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid var(--color-light-shade);
}

/*Fim dos estilos base -------------------------------------------*/
