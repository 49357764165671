@mixin font-has-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

@mixin font-lines-limit($size) {
  -webkit-line-clamp: $size;
}

.s-font-has-limit {
  @include font-has-limit;

  @for $i from 1 through 6 {
    &.--#{$i}-lines {
      @include font-lines-limit($i);
    }
  }
}
