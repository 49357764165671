.neutral-colors {
  --color-dark: #495057;
  --color-dark-rgb: 73, 80, 87;
  --color-dark-shade: #343a40;
  --color-dark-shade-rgb: 52, 58, 64;
  --color-dark-tint: #727980;
  --color-dark-tint-rgb: 114, 121, 128;

  --color-medium: #ced4da;
  --color-medium-rgb: 206, 212, 218;
  --color-medium-shade: #adb5bd;
  --color-medium-shade-rgb: 173, 181, 189;
  --color-medium-tint: #dee2e6;
  --color-medium-tint-rgb: 222, 226, 230;

  --color-light: #f3f5f7;
  --color-light-rgb: 243, 245, 247;
  --color-light-shade: #eceff2;
  --color-light-shade-rgb: 236, 239, 242;
  --color-light-tint: #ffffff;
  --color-light-tint-rgb: 255, 255, 255;

  --color-danger: #f64f77;
  --color-danger-rgb: 246, 79, 119;
  --color-danger-shade: #d64568;
  --color-danger-shade-rgb: 214, 69, 104;
  --color-danger-light: #ff7092;
  --color-danger-light-rgb: 255, 112, 146;
}
