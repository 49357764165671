.mat-expansion-panel {
  box-shadow: none !important;

  .mat-expansion-indicator {
    --mat-expansion-header-indicator-color: var(--color-dark-tint) !important;

    &::after {
      padding: 4px !important;
      margin-right: 4px;
      margin-top: -6px;
    }
  }
}
