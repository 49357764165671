@use "src/styles/base/breakpoints";

.m-business-front-logo {
  &.--header {
    display: block;

    width: auto;
    max-width: 132px;

    height: 100%;
    max-height: 28px;

    object-fit: contain;

    margin-right: 16px;

    @media (max-width: 350px) {
      width: 100%;
    }

    @media (min-width: breakpoints.$size-desktop) {
      max-width: 200px;
      max-height: 40px;
    }
  }

  &.--steps {
    display: block;

    width: max-content;
    max-width: 270px;

    height: 100%;
    max-height: 40px;

    object-fit: contain;
  }

  &.--footer {
    display: block;

    width: auto;
    max-width: 155px;

    height: 100%;
    max-height: 30px;

    object-fit: contain;
  }

  &.--error {
    display: block;

    width: auto;
    max-width: 200px;

    height: 100%;
    max-height: 28px;

    object-fit: contain;
  }
}

[data-business-front="vidav"] {
  .m-business-front-logo {
    &.--header {
      max-width: 124px;
      max-height: 24px;
    }

    &.--steps {
      max-width: 165px;
      max-height: 32px;
    }

    &.--footer {
      max-width: 103px;
      max-height: 20px;
    }

    &.--error {
      max-width: 124px;
      max-height: 24px;
    }
  }
}
