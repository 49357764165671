@use "../../base/colors";

[data-theme="indigo-bliss"] {
  --color-primary: #0099ff;
  --color-primary-rgb: 0, 153, 255;
  --color-primary-shade: #00498a;
  --color-primary-shade-rgb: 0, 73, 138;
  --color-primary-tint: #47b6ff;
  --color-primary-tint-rgb: 71, 182, 255;
  --color-primary-light: #e6f5ff;
  --color-primary-light-rgb: 230, 245, 255;

  --color-secondary: #00b9c7;
  --color-secondary-rgb: 0, 185, 199;
  --color-secondary-shade: #00858e;
  --color-secondary-shade-rgb: 0, 133, 142;
  --color-secondary-tint: #00d0e0;
  --color-secondary-tint-rgb: 0, 208, 224;

  --color-tertiary: #57257d;
  --color-tertiary-rgb: 87, 37, 125;
  --color-tertiary-shade: #2c1047;
  --color-tertiary-shade-rgb: 44, 16, 71;
  --color-tertiary-tint: #6f448f;
  --color-tertiary-tint-rgb: 111, 68, 143;

  @extend .neutral-colors;
}
