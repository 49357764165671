@use "src/styles/base/mixins/rem";

.l-dynamic-content {
  ul {
    list-style: none;
  }

  li {
    position: relative;

    padding-left: 23px;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    &::before {
      content: "";

      display: block;

      width: rem.rem(4px);
      height: rem.rem(4px);

      border-radius: 50%;

      position: absolute;
      top: rem.rem(9px);
      left: rem.rem(9px);

      background-color: var(--color-dark-tint);
    }
  }
}
