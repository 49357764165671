@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?nebayc');
  src:  url('fonts/icomoon.eot?nebayc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?nebayc') format('truetype'),
    url('fonts/icomoon.woff?nebayc') format('woff'),
    url('fonts/icomoon.svg?nebayc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user:before {
  content: "\e910";
}
.icon-vss-single:before {
  content: "\e90f";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-arrow-single-left:before {
  content: "\e902";
}
.icon-button-close:before {
  content: "\e903";
}
.icon-tick-circle:before {
  content: "\e904";
}
.icon-forbidden-2:before {
  content: "\e905";
}
.icon-card:before {
  content: "\e906";
}
.icon-barcode:before {
  content: "\e907";
}
.icon-main-component:before {
  content: "\e908";
}
.icon-hospital:before {
  content: "\e90a";
}
.icon-call-outgoing:before {
  content: "\e90b";
}
.icon-ligue-saude:before {
  content: "\e90c";
}
.icon-whatsapp:before {
  content: "\e909";
}
.icon-map:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e90e";
}
