@use "../base/breakpoints";

.m-anchor {
  display: block;
  transform: translateY(-72px);

  @media (min-width: breakpoints.$size-desktop) {
    transform: translateY(-94x);
  }
}
