@import "../../base/colors";

[data-theme="tropical-azure"] {
  --color-primary: #0066cc;
  --color-primary-rgb: 0, 102, 204;
  --color-primary-shade: #003d7a;
  --color-primary-shade-rgb: 0, 61, 122;
  --color-primary-tint: #3385d6;
  --color-primary-tint-rgb: 51, 133, 214;
  --color-primary-light: #e6f5ff;
  --color-primary-light-rgb: 230, 245, 255;

  --color-secondary: #99cc33;
  --color-secondary-rgb: 153, 204, 51;
  --color-secondary-shade: #5c7a1f;
  --color-secondary-shade-rgb: 92, 122, 31;
  --color-secondary-tint: #add65c;
  --color-secondary-tint-rgb: 173, 214, 92;

  --color-tertiary: #27a196;
  --color-tertiary-rgb: 39, 161, 150;
  --color-tertiary-shade: #17615a;
  --color-tertiary-shade-rgb: 23, 97, 90;
  --color-tertiary-tint: #52b4ab;
  --color-tertiary-tint-rgb: 39, 161, 150;

  @extend .neutral-colors;
}
