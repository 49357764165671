@use "../base/breakpoints";

@import "typography";

.s-is-hidden {
  border: 0;
  clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.s-is-hidden-desktop {
  @media (min-width: breakpoints.$size-desktop) {
    display: none !important;
  }
}

.s-is-hidden-mobile {
  @media (max-width: breakpoints.$size-desktop) {
    display: none !important;
  }
}

.s-has-shadow {
  box-shadow: 0px 10px 30px rgba(114, 121, 128, 0.1);
}
